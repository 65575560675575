interface PoolOrderConfig {
  marketIndexName: string
  marketPoolName: string
}

export const POOL_ORDER: PoolOrderConfig[] = [
  {
    marketIndexName: 'BTC/USD',
    marketPoolName: 'wETH-USDC.e',
  },
  {
    marketIndexName: 'BTC/USD',
    marketPoolName: 'wstETH-deUSD',
  },
  {
    marketIndexName: 'ETH/USD',
    marketPoolName: 'wETH-USDC.e',
  },
  {
    marketIndexName: 'ETH/USD',
    marketPoolName: 'wstETH-deUSD',
  },
  {
    marketIndexName: 'wstETH/USD',
    marketPoolName: 'wstETH-deUSD',
  },
  {
    marketIndexName: 'SOL/USD',
    marketPoolName: 'wETH-USDC.e',
  },
  {
    marketIndexName: 'SOL/USD',
    marketPoolName: 'wstETH-deUSD',
  },
  {
    marketIndexName: 'ZK/USD',
    marketPoolName: 'ZK-USDC.e',
  },
  {
    marketIndexName: 'SWAP-ONLY',
    marketPoolName: 'USDC.e-deUSD',
  },
  {
    marketIndexName: 'SWAP-ONLY',
    marketPoolName: 'wstETH-wETH',
  },
]

const reorderPools = <P extends PoolOrderConfig>(pools: P[]): P[] => {
  const orderedPools: P[] = []

  const restPools: P[] = []

  POOL_ORDER.forEach((orderConfig) => {
    const matchingPool = pools.find((pool) => {
      const marketPoolMatch = pool.marketPoolName === orderConfig.marketPoolName
      const marketIndexMatch =
        pool.marketIndexName === orderConfig.marketIndexName

      return marketPoolMatch && marketIndexMatch
    })

    if (matchingPool) {
      orderedPools.push(matchingPool)
    }
  })

  // Add any pools that weren't matched to restPools
  pools.forEach((pool) => {
    const isMatched = orderedPools.some(
      (orderedPool) =>
        orderedPool.marketPoolName === pool.marketPoolName &&
        orderedPool.marketIndexName === pool.marketIndexName,
    )
    if (!isMatched) {
      restPools.push(pool)
    }
  })

  return [...orderedPools, ...restPools]
}

export default reorderPools
