import Button from '@components/shared/Button'
import { useCreatePoolStore } from '@store/permissionlessCreatePoolStore'
import clsx from 'clsx'
import {
  getSyntheticsDepositIndexTokenKey,
  getSyntheticsDepositMarketKey,
} from 'config/localStorage'
import {
  MarketInfo,
  MarketsData,
  MarketsInfoData,
  getMarketIndexName,
} from 'domain/synthetics/markets'
import { useChainId } from 'rfx/lib/chains'
import { useLocalStorageSerializeKey } from 'rfx/lib/localStorage'
import { useCallback } from 'react'
import { PoolSelector } from './PoolSelector'

interface Props {
  nextStep: () => void
  marketsInfoData: MarketsInfoData
  marketsData: MarketsData
}

const Step1 = ({ nextStep, marketsInfoData, marketsData }: Props) => {
  const { chainId } = useChainId()
  const { tokenA, tokenB, setTokenA, setTokenB } = useCreatePoolStore()

  const [, setSelectedMarketKey] = useLocalStorageSerializeKey<
    string | undefined
  >(getSyntheticsDepositMarketKey(chainId), undefined)

  const [indexName, setIndexName] = useLocalStorageSerializeKey<
    string | undefined
  >(getSyntheticsDepositIndexTokenKey(chainId), undefined)

  const onMarketChange = useCallback(
    (marketAddress: string) => {
      setSelectedMarketKey(marketAddress)
    },
    [setSelectedMarketKey],
  )

  return (
    <div className="flex h-full flex-col justify-between">
      <div className="flex-1 space-y-6">
        <div className="space-y-2">
          <p className="text-sm font-medium leading-[17.5px] text-th-fgd-3">
            Token A
          </p>
          <PoolSelector
            chainId={chainId}
            selectedIndexName={indexName}
            selectedMarketAddress={tokenA || ''}
            markets={
              Object.values(marketsData).filter(
                (item) => !item.poolName?.includes('SWAP-ONLY'),
              ) as MarketInfo[]
            }
            marketTokensData={{}}
            marketsInfoData={marketsInfoData}
            showBalances
            showAllPools
            onSelectMarket={(marketInfo) => {
              setIndexName(getMarketIndexName(marketInfo))
              onMarketChange(marketInfo.indexTokenAddress)
              setTokenA(marketInfo.indexTokenAddress)
              // showMarketToast(marketInfo)
            }}
          />
        </div>
        <div className="space-y-2">
          <p className="text-sm font-medium leading-[17.5px] text-th-fgd-3">
            Token B
          </p>
          <PoolSelector
            chainId={chainId}
            selectedIndexName={indexName}
            selectedMarketAddress={tokenB || ''}
            markets={
              Object.values(marketsData).filter(
                (item) => !item.poolName?.includes('SWAP-ONLY'),
              ) as MarketInfo[]
            }
            marketTokensData={{}}
            marketsInfoData={marketsInfoData}
            showBalances
            showAllPools
            isBtnDisabled
            onSelectMarket={(marketInfo) => {
              setIndexName(getMarketIndexName(marketInfo))
              onMarketChange(marketInfo.indexTokenAddress)
              setTokenB(marketInfo.indexTokenAddress)
              // showMarketToast(marketInfo)
            }}
          />
        </div>
      </div>
      <Button
        className={clsx('w-full', '!bg-th-active')}
        type="submit"
        disabled={!tokenA || !tokenB}
        onClick={nextStep}
        size="large"
      >
        <span
          className={clsx(
            'text-sm font-bold uppercase leading-[18px]',
            !tokenA || !tokenB ? 'text-th-fgd-3' : 'text-th-fgd-1',
          )}
        >
          NEXT STEP
        </span>
      </Button>
    </div>
  )
}

export default Step1
