import EmptyState from '@components/shared/EmptyState'
import commonStore from '@store/commonStore'
import GmList from 'components/Synthetics/GmList/GmList'
import reorderPools from 'config/pools'
import { TokensData } from 'domain/synthetics/tokens'
import { PoolDataType } from 'hooks/usePerpsPoolData'

interface Props {
  marketTokensData: TokensData | undefined
  gmList: PoolDataType[]
}

const RenderEarnPageTable = ({ gmList, marketTokensData }: Props) => {
  const appliedFilter = commonStore((state) => state.appliedFilter)

  const reorderedPools = reorderPools(gmList)

  if (gmList.length === 0 && appliedFilter !== 'SHARED') {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <EmptyState text="No Pools Available" />
      </div>
    )
  }

  return (
    <div className="h-full">
      <GmList marketTokensData={marketTokensData} gmList={reorderedPools} />
    </div>
  )
}

export default RenderEarnPageTable
