import {
  GmSwapBox,
  Mode,
  Operation,
} from 'components/Synthetics/GmSwap/GmSwapBox/GmSwapBox'
import { MarketStats } from 'components/Synthetics/MarketStats/MarketStats'
import { getSyntheticsDepositMarketKey } from 'config/localStorage'
import { MarketsInfoData, useMarketTokensData } from 'domain/synthetics/markets'
import { useMarketTokensAPR } from 'domain/synthetics/markets/useMarketTokensAPR'
import { getTokenData, TokensData } from 'domain/synthetics/tokens'
import { useChainId } from 'rfx/lib/chains'
import { useLocalStorageSerializeKey } from 'rfx/lib/localStorage'
import { getByKey } from 'rfx/lib/objects'
import { usePendingTxns } from 'rfx/lib/usePendingTxns'
import useBreakpoints from 'hooks/useBreakpoints'
import { Dispatch, SetStateAction } from 'react'

interface Props {
  marketsInfoData: MarketsInfoData
  tokensData: TokensData | undefined
  shouldDisableValidation: boolean
  operation: Operation
  setOperation: Dispatch<SetStateAction<Operation>>
  mode: Mode
  setMode: Dispatch<SetStateAction<Mode>>
}

const EarnSwapBoxWithStats = ({
  marketsInfoData,
  tokensData,
  shouldDisableValidation,
  operation,
  setOperation,
  mode,
  setMode,
}: Props) => {
  const { chainId } = useChainId()
  const { above } = useBreakpoints()
  const [, setPendingTxns] = usePendingTxns()
  const { marketTokensData: depositMarketTokensData } = useMarketTokensData({
    isDeposit: true,
  })
  const { marketTokensData: withdrawalMarketTokensData } = useMarketTokensData({
    isDeposit: false,
  })
  const { marketsTokensAPRData, marketsTokensIncentiveAprData } =
    useMarketTokensAPR()
  const [selectedMarketKey, setSelectedMarketKey] = useLocalStorageSerializeKey<
    string | undefined
  >(getSyntheticsDepositMarketKey(chainId), undefined)

  const markets = Object.values(marketsInfoData)
  const marketInfo = getByKey(marketsInfoData, selectedMarketKey)

  const marketToken = getTokenData(
    operation === Operation.Deposit
      ? depositMarketTokensData
      : withdrawalMarketTokensData,
    selectedMarketKey,
  )

  return (
    <div>
      <GmSwapBox
        selectedMarketAddress={selectedMarketKey}
        markets={markets}
        shouldDisableValidation={shouldDisableValidation}
        marketsInfoData={marketsInfoData}
        tokensData={tokensData}
        onSelectMarket={setSelectedMarketKey}
        setPendingTxns={setPendingTxns}
        operation={operation}
        mode={mode}
        setMode={setMode}
        setOperation={setOperation}
      />
      {above.lg && operation !== Operation.Bridge && (
        <div className="px-4">
          <MarketStats
            marketsTokensAPRData={marketsTokensAPRData}
            marketsTokensIncentiveAprData={marketsTokensIncentiveAprData}
            marketTokensData={depositMarketTokensData}
            marketsInfoData={marketsInfoData}
            marketInfo={marketInfo}
            marketToken={marketToken}
          />
        </div>
      )}
    </div>
  )
}

export default EarnSwapBoxWithStats
