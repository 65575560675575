import Button from '@components/shared/Button'
import { useCreatePoolStore } from '@store/permissionlessCreatePoolStore'
import tradeboxStore from '@store/tradeboxStore'
import { ApproveTokenButton } from 'components/ApproveTokenButton/ApproveTokenButton'
import ExchangeInfoRow from 'components/Exchange/ExchangeInfoRow'
import ExchangeInfoRowValue from 'components/Exchange/ExchangeInfoRowValue'
import { getContract } from 'config/contracts'
import { useSyntheticsEvents } from 'context/SyntheticsEvents'
import {
  FeeItem,
  estimateExecuteDepositGasLimit,
  estimateExecuteWithdrawalGasLimit,
  getExecutionFee,
  getFeeItem,
  getTotalFeeItem,
  useGasLimits,
  useGasPrice,
} from 'domain/synthetics/fees'
import {
  Market,
  MarketsData,
  createDepositTxn,
  getTokenPoolType,
} from 'domain/synthetics/markets'
import {
  TokenData,
  convertToUsd,
  getNeedTokenApprove,
  getTokenData,
  useTokensAllowanceData,
  useTokensData,
} from 'domain/synthetics/tokens'
import { GmSwapFees } from 'domain/synthetics/trade'
import { getDepositAmounts } from 'domain/synthetics/trade/utils/deposit'
import { BigNumber } from 'ethers'
import { useChainId } from 'rfx/lib/chains'
import { formatAmount, formatAmountFree, parseValue } from 'rfx/lib/numbers'
import { getByKey } from 'rfx/lib/objects'
import { usePendingTxns } from 'rfx/lib/usePendingTxns'
import useWallet from 'rfx/lib/wallets/useWallet'
import { useMarketInfo } from 'hooks/useMarketInfo'
import { usePaymaster } from 'hooks/usePaymaster'
import { useTokenData } from 'hooks/useTokenData'
import { isEmpty, uniq } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { ImSpinner2 } from 'react-icons/im'
import { formatFactor } from 'utils/market-utils'
import { useAccount } from 'wagmi'

interface Props {
  prevStep: () => void
  marketsData: MarketsData
  handleCreation: (createDepositTxn: () => Promise<any>) => Promise<void>
}

const Step3 = ({ prevStep, marketsData, handleCreation }: Props) => {
  const {
    tokenA,
    tokenB,
    firstTokenInputValue,
    setFirstTokenInputValue,
    secondTokenInputValue,
    setSecondTokenInputValue,
    focusedInput,
    poolName,
  } = useCreatePoolStore()
  const { signer, account } = useWallet()
  const { chainId } = useChainId()
  const { gasLimits } = useGasLimits(chainId)
  const { gasPrice } = useGasPrice(chainId)
  const { tokensData, pricesUpdatedAt } = useTokensData()
  const { setPendingDeposit } = useSyntheticsEvents()
  const isDeposit = true
  const [isSubmitting, setIsSubmitting] = useState(false)
  const slippage = tradeboxStore((store) => store.slippage)
  const { sendPaymasterTransaction } = usePaymaster()

  const marketTokenAddress = (
    Object.values(marketsData || {})?.filter(
      (item) => item.indexTokenAddress === tokenA,
    )[0] as Market
  )?.marketTokenAddress

  const { marketTokensData: depositMarketTokensData } = useTokenData({
    isDeposit: true,
    marketsAddresses: [marketTokenAddress],
  })

  const [, setPendingTxns] = usePendingTxns()
  const { isConnected } = useAccount()

  const { marketsInfoData } = useMarketInfo([marketTokenAddress])

  const marketInfo = marketsInfoData
    ? marketsInfoData[marketTokenAddress]
    : undefined

  const selectedData: any = Object.values(marketsData)?.filter(
    (item: any) => item.indexTokenAddress === tokenA,
  )[0]
  const [marketTokenInputValue, setMarketTokenInputValue] = useState('')
  const firstTokenAmount = parseValue(
    firstTokenInputValue,
    selectedData?.longToken?.decimals || 0,
  )
  const firstTokenUsd = convertToUsd(
    firstTokenAmount,
    selectedData?.longToken?.decimals,
    isDeposit
      ? selectedData?.longToken?.prices?.minPrice
      : selectedData?.longToken?.prices?.maxPrice,
  )

  const secondTokenAmount = parseValue(
    secondTokenInputValue,
    selectedData?.shortToken?.decimals || 0,
  )
  const secondTokenUsd = convertToUsd(
    secondTokenAmount,
    selectedData?.shortToken?.decimals,
    isDeposit
      ? selectedData?.shortToken?.prices?.minPrice
      : selectedData?.shortToken?.prices?.maxPrice,
  )

  const marketToken: any = useMemo(() => {
    if (!marketTokenAddress || !depositMarketTokensData) {
      return {}
    }
    return getTokenData(depositMarketTokensData, marketTokenAddress)
  }, [marketTokenAddress, depositMarketTokensData])

  const marketTokenAmount = parseValue(
    marketTokenInputValue || '0',
    marketToken?.decimals || 0,
  )!

  const longToken = selectedData?.longToken
  const shortToken = selectedData?.shortToken

  const { longTokenInputState, shortTokenInputState } = useMemo(() => {
    if (!marketInfo) {
      return {}
    }

    const inputs: {
      address: string
      value: string
      amount?: BigNumber
      usd?: BigNumber
      token?: TokenData
      setValue: (val: string) => void
    }[] = []

    if (tokenA) {
      inputs.push({
        address: marketInfo?.longTokenAddress,
        value: firstTokenInputValue,
        setValue: setFirstTokenInputValue,
        amount: firstTokenAmount,
        usd: firstTokenUsd,
        token: selectedData.longToken,
      })
    }

    if (tokenB) {
      inputs.push({
        address: tokenB,
        value: secondTokenInputValue,
        setValue: setSecondTokenInputValue,
        amount: secondTokenAmount,
        usd: secondTokenUsd,
        token: selectedData.shortToken,
      })
    }

    const longTokenInputState =
      !!marketInfo &&
      inputs.find((input) => {
        return getTokenPoolType(marketInfo, input.address) === 'long'
      })
    const shortTokenInputState =
      !!marketInfo &&
      inputs.find((input) => {
        return getTokenPoolType(marketInfo, input.address) === 'short'
      })

    return {
      longTokenInputState,
      shortTokenInputState,
    }
  }, [
    tokenA,
    tokenB,
    firstTokenAmount,
    firstTokenInputValue,
    firstTokenUsd,
    marketInfo,
    selectedData,
    secondTokenAmount,
    secondTokenInputValue,
    secondTokenUsd,
    setFirstTokenInputValue,
    setSecondTokenInputValue,
  ])

  const amounts = useMemo(() => {
    if (
      !isDeposit ||
      !marketInfo ||
      isEmpty(marketInfo) ||
      !marketToken ||
      isEmpty(marketToken)
    ) {
      return undefined
    }

    return getDepositAmounts({
      marketInfo: marketInfo,
      marketToken,
      longToken: marketInfo?.longToken,
      shortToken: marketInfo?.shortToken,
      longTokenAmount: longTokenInputState?.amount || BigNumber.from(0),
      shortTokenAmount: shortTokenInputState?.amount || BigNumber.from(0),
      marketTokenAmount,
      includeLongToken: Boolean(longTokenInputState?.address),
      includeShortToken: Boolean(shortTokenInputState?.address),
      strategy: 'byCollaterals',
    })
  }, [
    isDeposit,
    longTokenInputState?.address,
    longTokenInputState?.amount,
    marketInfo,
    marketTokenAmount,
    marketToken,
    shortTokenInputState?.address,
    shortTokenInputState?.amount,
  ])

  const market: Market | undefined = getByKey(marketsData, marketToken?.address)
  const routerAddress = getContract(chainId, 'SyntheticsRouter')
  const payTokenAddresses = (function getPayTokenAddresses() {
    if (!marketToken) {
      return []
    }
    const addresses: string[] = []
    if (isDeposit) {
      if (amounts?.longTokenAmount?.gt(0) && longToken) {
        addresses.push(longToken.address)
      }
      if (amounts?.shortTokenAmount?.gt(0) && shortToken) {
        addresses.push(shortToken.address)
      }
    } else {
      addresses.push(marketToken.address)
    }
    return uniq(addresses)
  })()
  const { tokensAllowanceData } = useTokensAllowanceData(chainId, {
    spenderAddress: routerAddress,
    tokenAddresses: payTokenAddresses,
    skip: false,
  })

  const { executionFee } = useMemo(() => {
    if (!gasLimits || !gasPrice || !tokensData || !amounts) {
      return {}
    }

    const basisUsd = isDeposit
      ? BigNumber.from(0)
          .add(amounts?.longTokenUsd || 0)
          .add(amounts?.shortTokenUsd || 0)
      : amounts?.marketTokenUsd || BigNumber.from(0)

    const swapFee = getFeeItem(amounts.swapFeeUsd?.mul(-1), basisUsd)
    const swapPriceImpact = getFeeItem(
      amounts.swapPriceImpactDeltaUsd,
      basisUsd,
    )
    const totalFees = getTotalFeeItem(
      [swapPriceImpact, swapFee].filter(Boolean) as FeeItem[],
    )

    const fees: GmSwapFees = {
      swapFee,
      swapPriceImpact,
      totalFees,
    }

    const gasLimit = isDeposit
      ? estimateExecuteDepositGasLimit(gasLimits, {
          initialLongTokenAmount: amounts.longTokenAmount,
          initialShortTokenAmount: amounts.shortTokenAmount,
        })
      : estimateExecuteWithdrawalGasLimit(gasLimits, {})

    const executionFee = getExecutionFee(
      chainId,
      gasLimits,
      tokensData,
      gasLimit,
      gasPrice,
      BigNumber.from(3),
    )

    return {
      fees,
      executionFee,
    }
  }, [amounts, chainId, gasLimits, gasPrice, isDeposit, tokensData])

  const tokensToApprove = (function getTokensToApprove() {
    const addresses: string[] = []
    if (!tokensAllowanceData) {
      return addresses
    }
    if (isDeposit) {
      if (
        amounts?.longTokenAmount?.gt(0) &&
        longToken &&
        getNeedTokenApprove(
          tokensAllowanceData,
          longToken?.address,
          amounts.longTokenAmount,
        )
      ) {
        addresses.push(longToken.address)
      }
      if (
        amounts?.shortTokenAmount?.gt(0) &&
        shortToken &&
        getNeedTokenApprove(
          tokensAllowanceData,
          shortToken?.address,
          amounts.shortTokenAmount,
        )
      ) {
        addresses.push(shortToken.address)
      }
    }
    return uniq(addresses)
  })()

  const isAllowanceLoaded = Boolean(tokensAllowanceData)

  const submitButtonState = (function getSubmitButtonState() {
    if (payTokenAddresses.length > 0 && !isAllowanceLoaded) {
      return {
        text: `Loading...`,
        disabled: true,
      }
    }

    const onSubmit = async () => {
      setIsSubmitting(true)
      try {
        await handleCreation(onCreateDeposit)
      } finally {
        setIsSubmitting(false)
      }

      return
    }

    if (isSubmitting) {
      return {
        text: isDeposit ? `Buying RP...` : `Selling RP...`,
        disabled: true,
      }
    }
    if (tokensToApprove.length > 0 && marketToken) {
      const symbols = tokensToApprove.map((address) => {
        const token = getTokenData(tokensData, address)!
        return address === marketToken.address
          ? 'RP'
          : token?.assetSymbol ?? token?.symbol
      })
      const symbolsText = symbols.join(', ')
      return {
        text: `Pending ${symbolsText} approval`,
        disabled: true,
      }
    }
    const operationText = isDeposit ? `Buy` : `Sell`
    const text = `Confirm ${operationText}`
    return {
      text,
      onClick: onSubmit,
    }
  })()

  useEffect(
    function updateInputAmounts() {
      if (!marketToken || !marketInfo) {
        return
      }
      if (isDeposit) {
        if (['longCollateral', 'shortCollateral'].includes(focusedInput)) {
          if (!amounts?.longTokenUsd?.gt(0) && !amounts?.shortTokenUsd?.gt(0)) {
            setMarketTokenInputValue('')
            return
          }

          if (amounts) {
            setMarketTokenInputValue(
              amounts.marketTokenAmount.gt(0)
                ? formatAmountFree(
                    amounts.marketTokenAmount,
                    marketToken.decimals,
                  )
                : '',
            )
          }
        }
      }
    },
    [
      amounts,
      focusedInput,
      isDeposit,
      longTokenInputState,
      marketInfo,
      marketToken,
      marketTokenAmount,
      setFirstTokenInputValue,
      setMarketTokenInputValue,
      setSecondTokenInputValue,
      shortTokenInputState,
    ],
  )

  function onCreateDeposit() {
    if (
      !account ||
      !executionFee ||
      !marketToken ||
      !market ||
      !tokensData ||
      !signer
    ) {
      return Promise.resolve()
    }
    return createDepositTxn(chainId, signer, {
      account,
      initialLongTokenAddress: longToken?.address || market.longTokenAddress,
      initialShortTokenAddress: shortToken?.address || market.shortTokenAddress,
      longTokenSwapPath: [],
      shortTokenSwapPath: [],
      longTokenAmount: amounts?.longTokenAmount || BigNumber.from(0),
      shortTokenAmount: amounts?.shortTokenAmount || BigNumber.from(0),
      marketTokenAddress: marketToken.address,
      minMarketTokens: marketTokenAmount,
      executionFee: executionFee.feeTokenAmount,
      allowedSlippage: slippage,
      skipSimulation: false,
      tokensData,
      setPendingTxns,
      setPendingDeposit,
      pricesUpdatedAt,
      sendPaymasterTransaction,
    })
  }

  return (
    <div className="flex h-full flex-col justify-between">
      <div className="flex-1 space-y-6">
        <div>
          <h1 className="mb-4 text-xl font-bold leading-8 text-th-fgd-2">
            General
          </h1>
          <div className="space-y-1">
            <ExchangeInfoRow
              label={`Pool Name`}
              value={<ExchangeInfoRowValue text={poolName} />}
            />
            <ExchangeInfoRow
              label={`Collateral`}
              value={
                <ExchangeInfoRowValue
                  text={`${longToken?.symbol}/${shortToken?.symbol}`}
                />
              }
            />
            <ExchangeInfoRow
              label={`Initial Liquidity`}
              value={
                <ExchangeInfoRowValue
                  text={`${
                    firstTokenInputValue || 0
                  } ${longToken?.symbol}/${secondTokenInputValue} ${shortToken?.symbol}`}
                />
              }
            />
          </div>
        </div>
        <div>
          <h1 className="mb-4 text-xl font-bold leading-8 text-th-fgd-2">
            Market Parameters
          </h1>
          <div className="space-y-1">
            <ExchangeInfoRow
              label={`Maximum Leverage`}
              value={<ExchangeInfoRowValue text={'50'} />}
            />
            <ExchangeInfoRow
              label={`Open Interest Reserve Factor`}
              value={
                <ExchangeInfoRowValue
                  text={
                    marketInfo
                      ? `${formatFactor(
                          marketInfo?.openInterestReserveFactorLong ||
                            BigNumber.from(0),
                        )} / ${formatFactor(
                          marketInfo?.openInterestReserveFactorShort ||
                            BigNumber.from(0),
                        )}`
                      : '...'
                  }
                />
              }
            />
            <ExchangeInfoRow
              label={`Borrowing Factor`}
              value={
                <ExchangeInfoRowValue
                  text={
                    marketInfo
                      ? `${formatFactor(
                          marketInfo?.borrowingFactorLong || BigNumber.from(0),
                        )} / ${formatFactor(
                          marketInfo?.borrowingFactorShort || BigNumber.from(0),
                        )}`
                      : '...'
                  }
                />
              }
            />
            <ExchangeInfoRow
              label={`Funding Factor`}
              value={
                <ExchangeInfoRowValue
                  text={
                    marketInfo
                      ? `${formatAmount(marketInfo?.fundingFactor, 30, 8)}`
                      : '...'
                  }
                />
              }
            />
          </div>
        </div>
      </div>

      <div className="flex items-center justify-between space-x-6">
        <Button
          size="large"
          secondary
          className="flex-1 border-th-fgd-3 hover:!border-th-fgd-3"
          onClick={prevStep}
        >
          <span className="text-sm font-bold leading-[18px] text-th-fgd-1">
            GO BACK
          </span>
        </Button>

        {tokensToApprove && tokensToApprove.length > 0 ? (
          tokensToApprove.map((address) => {
            const token = getTokenData(tokensData, address)!
            return (
              <ApproveTokenButton
                key={address}
                tokenAddress={address}
                tokenSymbol={
                  address === marketToken?.address
                    ? 'RP'
                    : token.assetSymbol ?? token.symbol
                }
                spenderAddress={routerAddress}
              />
            )
          })
        ) : (
          <Button
            size="large"
            className="w-full flex-1 !bg-th-active"
            type="submit"
            onClick={submitButtonState.onClick}
            disabled={
              isSubmitting ||
              !executionFee ||
              !market ||
              !marketInfo ||
              !isConnected ||
              !!tokensToApprove.length
            }
          >
            <span className="text-sm font-bold leading-[18px] text-th-fgd-1">
              {isSubmitting ? (
                <ImSpinner2 className="spin text-th-active" />
              ) : !isConnected ? (
                'CONNECT WALLET'
              ) : (
                'CREATE POOL'
              )}
            </span>
          </Button>
        )}
      </div>
    </div>
  )
}

export default Step3
