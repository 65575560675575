import { APRTooltip } from '@components/earn/APRTooltip'
import Tooltip from '@components/shared/Tooltip'
import { AprInfo } from 'components/AprInfo/AprInfo'
import { particlesBoost } from '../../../../config/earn'
import { BigNumber, ethers } from 'ethers'
import { useBonusAPR } from 'hooks/useBonusAPR'
import { PoolDataType } from 'hooks/usePerpsPoolData'
import { useMemo } from 'react'
import { USD_DECIMALS } from 'rfx/lib/legacy'
import { cn } from 'utils/classnames'

export const getBoostandTotalTVLPool = (
  market: PoolDataType,
  totalTVLgrouped: {
    ZK: BigNumber
    'wETH-USDC.e': BigNumber
    stETH: BigNumber
  },
) => {
  const boost: string =
    particlesBoost[`${market.marketIndexName} [${market.marketPoolName}]`]
      ?.boost

  const group: keyof typeof totalTVLgrouped = market.marketPoolName.includes(
    'ZK',
  )
    ? 'ZK'
    : market.marketPoolName.includes('stETH') ||
        market.marketPoolName.includes('USDC.e-deUSD')
      ? 'stETH'
      : 'wETH-USDC.e'
  const totalTVLPool = totalTVLgrouped[group]

  return { boost, totalTVLPool, group }
}

export const calculateTotalAPR = (
  market: PoolDataType,
  bonusAPR?: BigNumber,
) => {
  const marketAPR = market.apr || BigNumber.from(0)

  let _bonusAPR = +ethers.utils.formatUnits(
    bonusAPR || BigNumber.from(0),
    USD_DECIMALS,
  )

  _bonusAPR = parseInt((_bonusAPR * 100).toString())
  return marketAPR.add(BigNumber.from(_bonusAPR))
}

export const APRCell = ({
  market,
  totalTVLgrouped,
  className,
}: {
  market: PoolDataType
  totalTVLgrouped: {
    ZK: BigNumber
    'wETH-USDC.e': BigNumber
    stETH: BigNumber
  }
  className?: string
}) => {
  const { boost, totalTVLPool, group } = getBoostandTotalTVLPool(
    market,
    totalTVLgrouped,
  )

  const bonusAPR = useBonusAPR(group, totalTVLPool)

  const apr = useMemo(() => {
    return calculateTotalAPR(market, bonusAPR)
  }, [market.apr, bonusAPR])

  return (
    <Tooltip
      className="w-[404px]"
      placement="top-end"
      content={
        <APRTooltip
          baseAPR={market.apr || BigNumber.from(0)}
          boost={boost ? `${boost}X Particle Boost` : '-'}
          bonusAPR={
            bonusAPR
              ? `${(+ethers.utils.formatUnits(bonusAPR, 30)).toFixed(2)}%`
              : undefined
          }
        />
      }
    >
      <AprInfo
        apr={apr}
        incentiveApr={market.incentiveApr}
        className={cn('!text-base', className)}
      />
    </Tooltip>
  )
}
