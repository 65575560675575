import { BigNumber } from 'ethers'
import usePerpsPoolData from './usePerpsPoolData'
import React from 'react'

function useTotalTVLperCollateral() {
  const gmList = usePerpsPoolData()

  const totalTVLperMarketPoolName = React.useMemo(() => {
    return gmList.reduce(
      (acc, curr) => {
        if (curr.marketPoolName.includes('ZK')) {
          acc.ZK = acc.ZK.add(curr.totalSupplyUsd || BigNumber.from(0))
        } else if (curr.marketPoolName.includes('wETH-USDC.e')) {
          acc['wETH-USDC.e'] = acc['wETH-USDC.e'].add(
            curr.totalSupplyUsd || BigNumber.from(0),
          )
        } else if (
          curr.marketPoolName.includes('stETH') ||
          curr.marketPoolName.includes('USDC.e-deUSD')
        ) {
          acc.stETH = acc.stETH.add(curr.totalSupplyUsd || BigNumber.from(0))
        }

        return acc
      },
      {
        ZK: BigNumber.from(0),
        'wETH-USDC.e': BigNumber.from(0),
        stETH: BigNumber.from(0),
      },
    )
  }, [gmList])

  return totalTVLperMarketPoolName
}

export default useTotalTVLperCollateral
