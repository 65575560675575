import { BigNumber, ethers } from 'ethers'
import useTokenPrice from './rfx/usePyth/useTokenPrice'
import React from 'react'
import { TOKENS } from 'config/tokens'
import { DEFAULT_CHAIN_ID } from 'config/chains'

const ZK_ALLOCATIONS = {
  ZK: 100_000,
  'wETH-USDC.e': 200_000,
  stETH: 376_715,
}

type ZKAllocation = keyof typeof ZK_ALLOCATIONS

export const calculateBonusAPR = (
  totalAlloc: number,
  zkPrice: BigNumber,
  totalGroupTVL: BigNumber,
) => {
  try {
    const etherTotalAlloc = ethers.utils.parseUnits(totalAlloc.toString(), 30)
    const a = BigNumber.from(etherTotalAlloc)
      .div(30)
      .mul(zkPrice)
      .mul(365)
      .div(totalGroupTVL)
      .mul(100)

    return a
  } catch (e) {
    return undefined
  }
}

export const useBonusAPR = (
  allocationGroup: ZKAllocation,
  totalGroupTVL: BigNumber,
) => {
  const zkToken = TOKENS[DEFAULT_CHAIN_ID].find((item) => item.symbol === 'ZK')
  const zkPrice = useTokenPrice(
    zkToken?.pythId || '',
    zkToken?.address || '',
    'maxPrice',
  )

  const totalAlloc = ZK_ALLOCATIONS[allocationGroup]

  const bonusAPR = React.useMemo(() => {
    return (
      calculateBonusAPR(totalAlloc, zkPrice, totalGroupTVL) || BigNumber.from(0)
    )
  }, [totalAlloc, totalGroupTVL, zkPrice])

  return bonusAPR
}
