import { BigNumber } from 'ethers'
import { formatAmount } from 'rfx/lib/numbers'
import React from 'react'
import { cn } from 'utils/classnames'

const ToolTipRow = ({
  label,
  value,
  labelClassName,
}: {
  label: string
  value: string | number | JSX.Element
  labelClassName?: string
}) => {
  return (
    <div className="flex items-center justify-between">
      <span className={cn('text-xs font-medium text-th-fgd-3', labelClassName)}>
        {label}
      </span>
      <span className="text-xs font-medium text-th-fgd-1">{value}</span>
    </div>
  )
}

export const APRTooltip = ({
  baseAPR,
  boost,
  bonusAPR,
}: {
  baseAPR: BigNumber
  boost: string
  bonusAPR?: string
}) => {
  const aprNode = (
    <span className={`font-semibold text-th-fgd-1`}>
      {baseAPR ? `${formatAmount(baseAPR, 2, 2)}%` : '...'}
    </span>
  )

  return (
    <div className="flex flex-col gap-2 p-2">
      <ToolTipRow label="BASE APR (7 day average)" value={aprNode} />
      <ToolTipRow label="BONUS APR (estimated)" value={bonusAPR || '-'} />

      <ToolTipRow
        labelClassName="text-xs font-medium text-th-fgd-1"
        label="Rewards"
        value={boost || '-'}
      />
    </div>
  )
}
