import {
  MarketInfo,
  MintableInfo,
  getMarketIndexName,
  getMarketPoolName,
  getMintableMarketTokens,
  useMarketTokensData,
  useMarketsInfo,
} from 'domain/synthetics/markets'
import { useMarketTokensAPR } from 'domain/synthetics/markets/useMarketTokensAPR'
import { TokenData, getTokenData } from 'domain/synthetics/tokens'
import { convertToUsd } from 'domain/synthetics/tokens/utils'
import useSortedMarketsWithIndexToken from 'domain/synthetics/trade/useSortedMarketsWithIndexToken'
import { BigNumber } from 'ethers'
import { formatUsdNumber } from 'rfx/lib/numbers'
import { getByKey } from 'rfx/lib/objects'
import { useMemo } from 'react'

export interface PoolDataType {
  apr: BigNumber | undefined
  balance: BigNumber | undefined
  incentiveApr: BigNumber | undefined
  indexToken: TokenData
  longToken: TokenData
  shortToken: TokenData
  market: MarketInfo | undefined
  mintableInfo: MintableInfo | undefined
  decimals: number
  mintableAmount: BigNumber | undefined
  marketIndexName: string
  marketPoolName: string
  price: BigNumber
  token: TokenData
  totalSupply: BigNumber | undefined
  totalSupplyUsd: BigNumber | undefined
  isSpotOnly: boolean
}

const usePerpsPoolData = () => {
  const { marketsInfoData = {}, tokensData } = useMarketsInfo()
  const { marketTokensData: depositMarketTokensData } = useMarketTokensData({
    isDeposit: true,
  })
  const { markets: sortedMarketsByIndexToken } = useSortedMarketsWithIndexToken(
    marketsInfoData,
    depositMarketTokensData,
  )

  const { marketsTokensAPRData, marketsTokensIncentiveAprData } =
    useMarketTokensAPR()

  sortedMarketsByIndexToken.sort((a, b) => {
    const aSupplyUsd =
      formatUsdNumber(
        convertToUsd(a.totalSupply, a.decimals, a.prices.minPrice),
      ) || 0
    const bSupplyUsd =
      formatUsdNumber(
        convertToUsd(b.totalSupply, b.decimals, b.prices.minPrice),
      ) || 0
    return aSupplyUsd > bSupplyUsd ? -1 : 1
  })

  const formattedPoolsData = useMemo(() => {
    const pools = sortedMarketsByIndexToken.map((token) => {
      const market = getByKey(marketsInfoData, token.address)
      const indexToken = getTokenData(
        tokensData,
        market?.indexTokenAddress,
        'native',
      )
      const longToken = getTokenData(tokensData, market?.longTokenAddress)
      const shortToken = getTokenData(tokensData, market?.shortTokenAddress)

      if (!indexToken || !longToken || !shortToken) {
        return null
      }

      const mintableInfo = market
        ? getMintableMarketTokens(market, token)
        : undefined
      const apr = getByKey(marketsTokensAPRData, token.address)
      const incentiveApr = getByKey(
        marketsTokensIncentiveAprData,
        token.address,
      )

      const totalSupply = token.totalSupply
      const totalSupplyUsd = convertToUsd(
        totalSupply,
        token.decimals,
        token.prices?.minPrice,
      )
      return {
        apr,
        balance: token.balance,
        incentiveApr,
        indexToken,
        longToken,
        shortToken,
        market,
        mintableInfo,
        decimals: token.decimals,
        mintableAmount: mintableInfo?.mintableAmount,
        marketIndexName: getMarketIndexName({
          indexToken,
          isSpotOnly: !!market?.isSpotOnly,
        }),
        marketPoolName: getMarketPoolName({ longToken, shortToken }),
        price: token.prices?.minPrice,
        token,
        totalSupply,
        totalSupplyUsd,
        isSpotOnly: !!market?.isSpotOnly,
      }
    })

    return pools.filter((pool): pool is PoolDataType => pool !== null)
  }, [
    sortedMarketsByIndexToken,
    marketsInfoData,
    tokensData,
    marketsTokensAPRData,
    marketsTokensIncentiveAprData,
  ])

  return formattedPoolsData
}

export default usePerpsPoolData
