import EmptyState from '@components/shared/EmptyState'
import commonStore from '@store/commonStore'
import { TokensData } from 'domain/synthetics/tokens'
import { PoolDataType } from 'hooks/usePerpsPoolData'
import GmListCard from './GmListCard'
import useTotalTVLperCollateral from 'hooks/useTotalTVLperCollateral'
import reorderPools from 'config/pools'

interface Props {
  marketTokensData: TokensData | undefined
  gmList: PoolDataType[]
}

const RenderGmListCards = ({ gmList }: Props) => {
  const appliedFilter = commonStore((state) => state.appliedFilter)
  const reorderedPools = reorderPools(gmList)

  const totalTVLgrouped = useTotalTVLperCollateral()

  if (gmList.length === 0 && appliedFilter !== 'SHARED') {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <EmptyState text="No Pools Available" />
      </div>
    )
  }
  return (
    <div className="flex h-full flex-col gap-4 overflow-y-auto p-4">
      {reorderedPools.map((data) => (
        <GmListCard
          key={data.market?.marketTokenAddress}
          data={data}
          totalTVLgrouped={totalTVLgrouped}
        />
      ))}
    </div>
  )
}

export default RenderGmListCards
