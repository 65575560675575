import { useCreatePoolStore } from '@store/permissionlessCreatePoolStore'
import ExchangeInfoRow from 'components/Exchange/ExchangeInfoRow'
import ExchangeInfoRowValue from 'components/Exchange/ExchangeInfoRowValue'
import { DEFAULT_CHAIN_ID } from 'config/chains'
import { getWrappedToken } from 'config/tokens'
import {
  getAvailableUsdLiquidityForPosition,
  MarketInfo,
} from 'domain/synthetics/markets'
import { TokenData } from 'domain/synthetics/tokens'
import { BigNumber, ethers } from 'ethers'
import { formatUsd } from 'rfx/lib/numbers'
import groupBy from 'lodash/groupBy'
import { useMemo } from 'react'

const PoolGeneralInfo = ({
  currentMarketInfo,
}: {
  currentMarketInfo: MarketInfo
}) => {
  const { marketsInfoDataStore } = useCreatePoolStore()
  const groupedIndexMarkets = useMemo(() => {
    const marketsWithMaxReservedUsd = Object.values(
      marketsInfoDataStore.marketsInfoData,
    ).map((marketInfo) => {
      const maxLongLiquidity = getAvailableUsdLiquidityForPosition(
        marketInfo,
        true,
      )
      const maxShortLiquidity = getAvailableUsdLiquidityForPosition(
        marketInfo,
        false,
      )

      return {
        maxLongLiquidity: maxLongLiquidity.gt(0)
          ? maxLongLiquidity
          : BigNumber.from(0),
        maxShortLiquidity: maxShortLiquidity.gt(0)
          ? maxShortLiquidity
          : BigNumber.from(0),
        marketTokenAddress: marketInfo.marketTokenAddress,
        indexTokenAddress: marketInfo.indexTokenAddress,
      }
    })
    const groupedMarketsWithIndex = groupBy(
      marketsWithMaxReservedUsd,
      (market) => market.indexTokenAddress,
    )

    return groupedMarketsWithIndex
  }, [marketsInfoDataStore?.marketsInfoData])

  function getMaxLongShortLiquidityPool(token: TokenData) {
    const indexTokenAddress = token.isNative
      ? getWrappedToken(DEFAULT_CHAIN_ID).address
      : token.address
    const currentMarkets =
      groupedIndexMarkets[
        ethers.utils.getAddress(indexTokenAddress?.toLowerCase()!)
      ]
    const maxLongLiquidityPool = currentMarkets?.reduce((prev, current) => {
      if (!prev.maxLongLiquidity || !current.maxLongLiquidity) {
        return current
      }
      return prev.maxLongLiquidity.gt(current.maxLongLiquidity) ? prev : current
    })

    const maxShortLiquidityPool = currentMarkets?.reduce((prev, current) => {
      if (!prev.maxShortLiquidity || !current.maxShortLiquidity) {
        return current
      }
      return prev.maxShortLiquidity.gt(current.maxShortLiquidity)
        ? prev
        : current
    })
    return {
      maxLongLiquidityPool,
      maxShortLiquidityPool,
    }
  }
  const { maxLongLiquidityPool, maxShortLiquidityPool } =
    getMaxLongShortLiquidityPool(currentMarketInfo.indexToken)

  return (
    <div className="space-y-2 py-4">
      <ExchangeInfoRow
        label={`Pool Name`}
        value={<ExchangeInfoRowValue text={currentMarketInfo?.name} />}
      />
      <ExchangeInfoRow
        label={`Collateral`}
        value={
          <ExchangeInfoRowValue
            text={`${currentMarketInfo?.longToken?.symbol}/${currentMarketInfo?.shortToken?.symbol}`}
          />
        }
      />
      <ExchangeInfoRow
        label={`Initial Liquidity`}
        value={
          <ExchangeInfoRowValue
            text={`${formatUsd(
              maxLongLiquidityPool?.maxLongLiquidity,
            )} / ${formatUsd(maxShortLiquidityPool?.maxShortLiquidity)}`}
          />
        }
      />
    </div>
  )
}

export default PoolGeneralInfo
