import TokenFilters from '@components/shared/TokenFilters'
import { useCreatePoolStore } from '@store/permissionlessCreatePoolStore'
import useSettingsStore from '@store/settingsStore'
import SearchInput from 'components/SearchInput/SearchInput'
import useBreakpoints from 'hooks/useBreakpoints'
import { Dispatch, SetStateAction } from 'react'
import { PoolViewOptions } from '../../config/earn'

interface Props {
  appliedFilter: string
  searchQuery: string
  setSearchQuery: Dispatch<SetStateAction<string>>
  updatedFilterCount: {
    label: string
    count: number
  }[]
  handleTokenFilterChange: (val: string) => void
  showCreatePoolModal: () => void
}

const EarnHeader = ({
  appliedFilter,
  handleTokenFilterChange,
  searchQuery,
  setSearchQuery,
  updatedFilterCount,
}: Props) => {
  const { below } = useBreakpoints()
  const isPro = useSettingsStore((state) => state.isPro())
  const { poolView, setPoolView } = useCreatePoolStore()

  const renderSerachInput = () => (
    <SearchInput
      placeholder="Search by ETH, 0x..., DeFi, AMM or Labs"
      className="h-full w-full rounded-none border-none bg-transparent"
      inputBoxClasses="text-sm h-full w-full"
      value={searchQuery}
      setValue={(event) => setSearchQuery(event.target.value.trim())}
    />
  )

  const renderTokenFilters = () => (
    <TokenFilters
      appliedFilter={appliedFilter}
      changeFilter={handleTokenFilterChange}
      options={updatedFilterCount}
      variant={below.lg ? 'dropdown' : 'regular'}
    />
  )

  if (below.lg) {
    return (
      <div className="flex w-full items-center justify-between overflow-x-auto border-b border-th-input-border px-4 py-2">
        <div className="relative">{renderTokenFilters()}</div>
        <div className="flex items-center gap-2">
          {PoolViewOptions.map((opt, idx) => {
            const isSelected = poolView === opt.value
            const IconComponent = isSelected ? opt.activeIcon : opt.defaultIcon

            return (
              <div key={idx} onClick={() => setPoolView(opt.value)}>
                <IconComponent />
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  if (isPro) {
    return (
      <>
        <div className="flex h-[32px] w-full items-center justify-between border-b border-th-input-border pr-[16px]">
          {renderSerachInput()}
        </div>
        <div className="flex h-[40px] items-center border-b border-th-input-border px-[16px]">
          {renderTokenFilters()}
        </div>
      </>
    )
  }

  return (
    <>
      <div className="flex h-14 w-full items-center justify-between border-b border-th-input-border pl-2 pr-6">
        {renderSerachInput()}
      </div>
      <div className="flex h-12 items-center border-b border-th-input-border px-6">
        {renderTokenFilters()}
      </div>
    </>
  )
}
export default EarnHeader
