import ExchangeInfoRow from 'components/Exchange/ExchangeInfoRow'
import ExchangeInfoRowValue from 'components/Exchange/ExchangeInfoRowValue'
import { MarketInfo } from 'domain/synthetics/markets'
import { formatUsd } from 'rfx/lib/numbers'
import { formatAmountHuman } from 'utils/permissionless'

const PoolMarketParameters = ({
  currentMarketInfo,
}: {
  currentMarketInfo: MarketInfo
}) => {
  function renderPoolCapCell(isLong: boolean) {
    const poolAmount = isLong
      ? currentMarketInfo.longPoolAmount
      : currentMarketInfo.shortPoolAmount

    const maxPoolUsdForDeposit = isLong
      ? currentMarketInfo.maxLongTokenPoolUsdForDeposit
      : currentMarketInfo.maxShortTokenPoolUsdForDeposit
    const token = isLong
      ? currentMarketInfo.longToken
      : currentMarketInfo.shortToken

    return `${formatAmountHuman(poolAmount, token.decimals)}${token.symbol} /
    ${formatUsd(maxPoolUsdForDeposit, {
      displayDecimals: 0,
    })}`
  }
  return (
    <div className="space-y-2 py-4">
      <ExchangeInfoRow
        label={`Long Cap Amount`}
        value={<ExchangeInfoRowValue text={renderPoolCapCell(true)} />}
      />
      <ExchangeInfoRow
        label={`Short Cap Amount`}
        value={<ExchangeInfoRowValue text={renderPoolCapCell(false)} />}
      />
      <ExchangeInfoRow
        label={`Maximum Leverage`}
        value={<ExchangeInfoRowValue text={'50'} />}
      />
      <ExchangeInfoRow
        label={`Open Interest (Available/Maximum)`}
        value={
          <ExchangeInfoRowValue
            text={`$${formatAmountHuman(
              currentMarketInfo.longInterestUsd,
              30,
            )} / $${formatAmountHuman(currentMarketInfo.shortInterestUsd, 30)}`}
          />
        }
      />
      <ExchangeInfoRow
        label={`Borrowing Fee`}
        value={
          <ExchangeInfoRowValue
            text={`$${formatAmountHuman(
              currentMarketInfo.totalBorrowingFees,
              30,
            )}`}
          />
        }
      />
      <ExchangeInfoRow
        label={`Funding Fee`}
        value={<ExchangeInfoRowValue text="0.0%" />}
      />
    </div>
  )
}

export default PoolMarketParameters
