import { DEFAULT_CHAIN_ID } from 'config/chains'
import { TOKENS, Token } from 'config/tokens'
import { formatAmount } from 'rfx/lib/numbers'
import { BigNumberish } from 'ethers'

type chips = {
  label: string
  count: number
}

export const existingPoolsFilters: Record<string, chips> = {
  // MYPOOLS: { label: 'MY POOLS', count: 0 },
  All: { label: 'All', count: 0 },
  BTC: { label: 'BTC', count: 0 },
  ETH: { label: 'ETH', count: 0 },
  CRYPTO: { label: 'CRYPTO', count: 0 },
}

export const tokenPairsChipsIntialData: Record<string, chips> = {
  All: { label: 'All', count: 0 },
  Crypto: { label: 'Crypto', count: 0 },
  Forex: { label: 'Forex', count: 0 },
}

export const steps = [
  { label: 'Select Token Pair' },
  { label: 'Fund your pool' },
  // { label: 'Choose Rewards' },
  { label: 'Confirm Pool Info' },
  { label: 'waiting for pool to be created...' },
]

export const stepsHeadings: Record<
  number,
  {
    title: string
    subTitle: string
  }
> = {
  0: {
    title: 'Select Token Pair',
    subTitle: 'Proper risk analysis is done on your selected pairs.',
  },
  1: {
    title: 'Fund your pool',
    subTitle: 'Provide a name and liquidity to your pool',
  },
  // 2: {
  //   title: 'Choose Rewards',
  //   subTitle: 'Choose the desired LP Tokens and Fee',
  // },
  2: {
    title: 'Confirm Pool Info',
    subTitle: 'Your pool would be subjected to the following parameters.',
  },
  3: {
    title: 'Waiting for pool to be created...',
    subTitle: '',
  },
}

export const tokens: Token[] = TOKENS[DEFAULT_CHAIN_ID].filter((token) => {
  if (token.isSynthetic) {
    return token
  }
})

export const contracts: { label: string; address: string; url: string }[] = [
  {
    label: 'Pool',
    address: '0x6Ed13984664B3A2B7A46d3646dcB523d927839aa',
    url: 'https://www.google.co.in/',
  },
  {
    label: 'Token',
    address: '0x6Ed13984664B3A2B7A46d3646dcB523d927839aa',
    url: 'https://www.google.co.in/',
  },
  {
    label: 'Oracle',
    address: '0x6Ed13984664B3A2B7A46d3646dcB523d927839aa',
    url: 'https://www.google.co.in/',
  },
]

export const riskRatingColorScheme: Record<
  string,
  { color: string; textShadow: string }
> = {
  high: {
    color: '#D56E6E',
    textShadow: '0px 0px 14.6px #D56E6E, 0px 0px 133.266px #D56E6E',
  },
  medium: {
    color: '#6ED5A3',
    textShadow: '0px 0px 14.6px #, 0px 0px 133.266px #6ED5A3',
  },
  low: {
    color: 'hsl(33, 100%, 57%)',
    textShadow:
      '0px 0px 14.6px hsl(33, 100%, 57%), 0px 0px 133.266px hsl(33, 100%, 57%)',
  },
}

export const feeTierOptions = [
  {
    value: 0.05,
    heading: '~ Used by 5% users',
  },
  {
    value: 0.3,
    heading: '~ Used by 90% users',
  },
  {
    value: 1,
    heading: '~ Used by 5% users',
  },
]

export function formatAmountHuman(
  amount: BigNumberish | undefined,
  tokenDecimals: number,
  showDollar = false,
) {
  const n = Number(formatAmount(amount, tokenDecimals))
  const isNegative = n < 0
  const absN = Math.abs(n)
  const sign = showDollar ? '$' : ''

  if (absN >= 1000000) {
    return `${isNegative ? '-' : ''}${sign}${(absN / 1000000).toFixed(1)}M`
  }
  if (absN >= 1000) {
    return `${isNegative ? '-' : ''}${sign}${(absN / 1000).toFixed(1)}K`
  }
  return `${isNegative ? '-' : ''}${sign}${absN.toFixed(1)}`
}
