import Tooltip from '@components/shared/Tooltip'
import CardRow from 'components/CardRow/CardRow'
import StatsTooltipRow from 'components/StatsTooltip/StatsTooltipRow'
import { getBridgingOptionsForToken } from 'config/bridging'
import {
  MarketInfo,
  MarketTokensAPRData,
  MarketsInfoData,
  getMarketIndexName,
  getMarketPoolName,
  getMintableMarketTokens,
  getPoolUsdWithoutPnl,
  getSellableMarketToken,
} from 'domain/synthetics/markets'
import {
  TokenData,
  TokensData,
  convertToTokenAmount,
  convertToUsd,
} from 'domain/synthetics/tokens'
import { BigNumber } from 'ethers'
import { useChainId } from 'rfx/lib/chains'
import {
  formatTokenAmount,
  formatTokenAmountWithUsd,
  formatUsd,
} from 'rfx/lib/numbers'
import BridgingInfo from '../BridgingInfo/BridgingInfo'
import usePerpsPoolData from 'hooks/usePerpsPoolData'
import useTotalTVLperCollateral from 'hooks/useTotalTVLperCollateral'
import { APRCell } from '../GmList/APRCell'
import { useMemo } from 'react'

type Props = {
  marketsInfoData?: MarketsInfoData
  marketTokensData?: TokensData
  marketInfo?: MarketInfo
  marketToken?: TokenData
  marketsTokensAPRData: MarketTokensAPRData | undefined
  marketsTokensIncentiveAprData: MarketTokensAPRData | undefined
}

export function MarketStats(p: Props) {
  const { marketInfo, marketToken } = p
  const { chainId } = useChainId()
  const formattedPoolsData = usePerpsPoolData()
  const totalTVLgrouped = useTotalTVLperCollateral()

  const marketPrice = marketToken?.prices?.maxPrice
  const marketBalance = marketToken?.balance
  const marketBalanceUsd = convertToUsd(
    marketBalance,
    marketToken?.decimals,
    marketPrice,
  )
  const marketTotalSupply = marketToken?.totalSupply
  const marketTotalSupplyUsd = convertToUsd(
    marketTotalSupply,
    marketToken?.decimals,
    marketPrice,
  )

  const { longToken, shortToken, longPoolAmount, shortPoolAmount } =
    marketInfo || {}

  const mintableInfo =
    marketInfo && marketToken
      ? getMintableMarketTokens(marketInfo, marketToken)
      : undefined
  const sellableInfo =
    marketInfo && marketToken
      ? getSellableMarketToken(marketInfo, marketToken)
      : undefined

  const maxLongSellableTokenAmount = convertToTokenAmount(
    sellableInfo?.maxLongSellableUsd,
    longToken?.decimals,
    longToken?.prices.minPrice,
  )

  const maxShortSellableTokenAmount = convertToTokenAmount(
    sellableInfo?.maxShortSellableUsd,
    shortToken?.decimals,
    shortToken?.prices.minPrice,
  )

  const longPoolAmountUsd = marketInfo?.longToken
    ? getPoolUsdWithoutPnl(marketInfo, true, 'midPrice')
    : undefined
  const shortPoolAmountUsd = marketInfo?.longToken
    ? getPoolUsdWithoutPnl(marketInfo, false, 'midPrice')
    : undefined

  const indexName = marketInfo && getMarketIndexName(marketInfo)
  const poolName = marketInfo && getMarketPoolName(marketInfo)

  const bridgingOprionsForToken = getBridgingOptionsForToken(longToken?.symbol)
  const shouldShowMoreInfo = Boolean(bridgingOprionsForToken)

  const selectedMarket = useMemo(() => {
    if (!formattedPoolsData || !formattedPoolsData.length || !marketToken) {
      return
    }

    return formattedPoolsData.find((item) => {
      return (
        item.market?.marketTokenAddress.toLowerCase() ===
        marketToken.address.toLowerCase()
      )
    })
  }, [marketToken, formattedPoolsData])

  return (
    <div className="space-y-2">
      <CardRow
        label="Pool"
        value={indexName && poolName ? <>{indexName}</> : '...'}
        subValue={<>[{poolName}]</>}
      />

      <CardRow label={`Fees and Price Impact`} value={0} />

      <CardRow
        label={`Market`}
        value={indexName && poolName ? <>{indexName}</> : '...'}
        subValue={<>[{poolName}]</>}
      />
      <CardRow
        label={`Price`}
        value={
          <Tooltip
            content={
              <div>
                RP Token pricing includes positions' Pending PnL, Impact Pool
                Amount and Borrow Fees.
              </div>
            }
          >
            {formatUsd(marketPrice, {
              displayDecimals: 3,
            }) || '...'}
          </Tooltip>
        }
      />

      <CardRow
        label={`Wallet`}
        value={
          <>
            {
              formatTokenAmountWithUsd(
                marketBalance || BigNumber.from(0),
                marketBalanceUsd || BigNumber.from(0),
                'RP',
                marketToken?.decimals ?? 18,
              )?.split('(')[0]
            }
          </>
        }
        subValue={
          <>
            {'('}
            {
              formatTokenAmountWithUsd(
                marketBalance || BigNumber.from(0),
                marketBalanceUsd || BigNumber.from(0),
                'RP',
                marketToken?.decimals ?? 18,
              )?.split('(')[1]
            }
          </>
        }
      />

      <CardRow
        label={`APR`}
        value={
          selectedMarket ? (
            <APRCell
              market={selectedMarket}
              totalTVLgrouped={totalTVLgrouped}
              className="!text-sm !font-medium"
            />
          ) : (
            '-'
          )
        }
      />

      <div className="h-2" />

      <CardRow
        label={`Total Supply`}
        value={
          marketTotalSupply && marketTotalSupplyUsd ? (
            <>
              {
                formatTokenAmountWithUsd(
                  marketTotalSupply,
                  marketTotalSupplyUsd,
                  'RP',
                  marketToken?.decimals,
                  {
                    displayDecimals: 0,
                  },
                )?.split('(')[0]
              }
            </>
          ) : (
            '...'
          )
        }
        subValue={
          marketTotalSupply &&
          marketTotalSupplyUsd && (
            <>
              {'('}
              {
                formatTokenAmountWithUsd(
                  marketTotalSupply,
                  marketTotalSupplyUsd,
                  'RP',
                  marketToken?.decimals,
                  {
                    displayDecimals: 0,
                  },
                )?.split('(')[1]
              }
            </>
          )
        }
      />

      <CardRow
        label={`Buyable`}
        value={
          mintableInfo && marketTotalSupplyUsd && marketToken ? (
            <Tooltip
              content={
                <div>
                  {marketInfo?.isSameCollaterals ? (
                    <>
                      {marketInfo?.longToken.symbol} can be used to buy RP for
                      this market up to the specified buying caps.
                    </>
                  ) : (
                    <>
                      {marketInfo?.longToken.symbol} and{' '}
                      {marketInfo?.shortToken.symbol} can be used to buy RP for
                      this market up to the specified buying caps.
                    </>
                  )}

                  <br />
                  <br />

                  <StatsTooltipRow
                    label={`Max ${marketInfo?.longToken.symbol}`}
                    value={[
                      formatTokenAmount(
                        mintableInfo?.longDepositCapacityAmount,
                        marketInfo?.longToken.decimals,
                        marketInfo?.longToken.symbol,
                      ),
                      `(${formatTokenAmount(
                        marketInfo?.longPoolAmount,
                        marketInfo?.longToken.decimals,
                        undefined,
                        {
                          displayDecimals: 0,
                        },
                      )} / ${formatTokenAmount(
                        marketInfo?.maxLongPoolAmount,
                        marketInfo?.longToken.decimals,
                        marketInfo?.longToken.symbol,
                        { displayDecimals: 0 },
                      )})`,
                    ]}
                    showDollar={false}
                  />

                  <br />

                  {!marketInfo?.isSameCollaterals && (
                    <StatsTooltipRow
                      label={`Max ${marketInfo?.shortToken.symbol}`}
                      value={[
                        formatTokenAmount(
                          mintableInfo?.shortDepositCapacityAmount,
                          marketInfo?.shortToken.decimals,
                          marketInfo?.shortToken.symbol,
                        ),
                        `(${formatTokenAmount(
                          marketInfo?.shortPoolAmount,
                          marketInfo?.shortToken.decimals,
                          undefined,
                          { displayDecimals: 0 },
                        )} / ${formatTokenAmount(
                          marketInfo?.maxShortPoolAmount,
                          marketInfo?.shortToken.decimals,
                          marketInfo?.shortToken.symbol,
                          { displayDecimals: 0 },
                        )})`,
                      ]}
                      showDollar={false}
                    />
                  )}
                </div>
              }
            >
              {
                formatTokenAmountWithUsd(
                  mintableInfo.mintableAmount,
                  mintableInfo.mintableUsd,
                  'RP',
                  marketToken?.decimals,
                  {
                    displayDecimals: 0,
                  },
                )?.split('(')[0]
              }
            </Tooltip>
          ) : (
            '...'
          )
        }
        subValue={
          mintableInfo &&
          marketToken && (
            <>
              {'('}
              {
                formatTokenAmountWithUsd(
                  mintableInfo.mintableAmount,
                  mintableInfo.mintableUsd,
                  'RP',
                  marketToken?.decimals,
                  {
                    displayDecimals: 0,
                  },
                )?.split('(')[1]
              }
            </>
          )
        }
      />

      <CardRow
        label={`Sellable`}
        value={
          <Tooltip
            content={
              <div>
                <>
                  RP can be sold for {longToken?.symbol} and{' '}
                  {shortToken?.symbol} for this market up to the specified
                  selling caps. The remaining tokens in the pool are reserved
                  for currently open Positions.
                </>
                <br />
                <br />
                <StatsTooltipRow
                  label={`Max ${marketInfo?.longToken.symbol}`}
                  value={formatTokenAmountWithUsd(
                    maxLongSellableTokenAmount,
                    sellableInfo?.maxLongSellableUsd,
                    longToken?.symbol,
                    longToken?.decimals,
                  )}
                  showDollar={false}
                />
                <StatsTooltipRow
                  label={`Max ${marketInfo?.shortToken.symbol}`}
                  value={formatTokenAmountWithUsd(
                    maxShortSellableTokenAmount,
                    sellableInfo?.maxShortSellableUsd,
                    shortToken?.symbol,
                    shortToken?.decimals,
                  )}
                  showDollar={false}
                />
              </div>
            }
          >
            {
              formatTokenAmountWithUsd(
                sellableInfo?.totalAmount,
                sellableInfo?.totalUsd,
                'RP',
                marketToken?.decimals,
                {
                  displayDecimals: 0,
                },
              )?.split('(')[0]
            }
          </Tooltip>
        }
        subValue={
          sellableInfo &&
          marketToken && (
            <>
              {'('}
              {
                formatTokenAmountWithUsd(
                  sellableInfo?.totalAmount,
                  sellableInfo?.totalUsd,
                  'RP',
                  marketToken?.decimals,
                  {
                    displayDecimals: 0,
                  },
                )?.split('(')[1]
              }
            </>
          )
        }
      />

      <CardRow label={`Long Collateral`} value={longToken?.symbol || '...'} />
      <CardRow
        label={`Pool Amount`}
        value={
          <>
            {
              formatTokenAmountWithUsd(
                longPoolAmount,
                longPoolAmountUsd,
                longToken?.symbol,
                longToken?.decimals,
              )?.split('(')[0]
            }
          </>
        }
        subValue={
          <>
            {`(${
              formatTokenAmountWithUsd(
                longPoolAmount,
                longPoolAmountUsd,
                longToken?.symbol,
                longToken?.decimals,
              )?.split('(')[0]
            })`}
          </>
        }
      />
      {shouldShowMoreInfo && (
        <CardRow
          label={`More Info`}
          value={
            <BridgingInfo chainId={chainId} tokenSymbol={longToken?.symbol} />
          }
        />
      )}

      <CardRow label={`Short Collateral`} value={shortToken?.symbol || '...'} />
      <CardRow
        label={`Pool Amount`}
        value={
          <>
            {
              formatTokenAmountWithUsd(
                shortPoolAmount,
                shortPoolAmountUsd,
                shortToken?.symbol,
                shortToken?.decimals,
              )?.split('(')[0]
            }
          </>
        }
        subValue={
          <>
            {'('}
            {
              formatTokenAmountWithUsd(
                shortPoolAmount,
                shortPoolAmountUsd,
                shortToken?.symbol,
                shortToken?.decimals,
              )?.split('(')[1]
            }
          </>
        }
      />
    </div>
  )
}
